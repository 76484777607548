<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <button type="submit" v-on:click.prevent="backToSurvey" class="btn btn-primary waves-effect pull-right">Back to Survey</button>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <div class="no-margin" style="font-size: 25px; text-align: center;">
                            Thank you for filling out our survey!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    var self;
    export default {
        name: "SurveySuccess",
        data() {
            return {
            }
        },
        beforeMount: function() {
            self = this;
        },
        mounted: function() {
        },
        methods: {
            backToSurvey: function() {
                self.$router.push("/");
            },
        },
    }
</script>