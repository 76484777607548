<template>
    <div id="page-content-wrapper" class="admin-content-area">
        <div class="container-fluid xyz">
            <div class="page-header-top-container">
                <div v-if="$route.params.id > 0" class="page-name">Edit Company</div>
                <div v-else class="page-name">Add Company</div>
            </div>
            <!--page content-->
            <div class="admin-page-content">
                <div class="card-body card-body-padding-none">
                    <!-- Forms -->
                    <div class="forms-container">
                        <div class="row no-margin">
                            <b-form id="quiz_add_update" class="CustomChangeProfile">
                                <template>
                                    <!-- Company Name-->
                                    <div class="col-6 forms-block">
                                        <div class="col-3 paddingleft0">
                                            <div class="form-label">Company Name:</div>
                                        </div>
                                        <div class="col-9 paddingleft0">
                                            <b-form-input v-model="companyName" type="text" class="form-control" id="companyName" placeholder="Enter Company Name"></b-form-input>
                                            <div class="requiredstar">*</div>
                                            <font class="text-danger">{{ companyNameError }}</font>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12 upload-img-block">
                                        <div class="form-group">
                                            <label for="dropzone">Upload Image</label>
                                            <vue-dropzone
                                                    ref="dropzone"
                                                    id="dropzone"
                                                    :options="dropzoneOptions"
                                                    @vdropzone-success = "uploadDocument"
                                                    @vdropzone-removed-file="removedSelectedFile"
                                                    @vdropzone-error="fileError"
                                                    @vdropzone-file-added="fileAdd"
                                                    :useCustomSlot=true
                                            >
                                                <div class="dropzone-custom-content">
                                                    <h4 class="dropzone-custom-title">Drop files here or click to upload.</h4>
                                                    <div class="subtitle">Select files from your computer.</div>
                                                </div>

                                            </vue-dropzone>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="requiredstar">*</div>
                                        <font class="text-danger">{{ fileUploadError }}</font>
                                    </div>
                                    <div class="clearfix"></div>

                                    <!-- 5th Row -->
                                    <div style="clear:both;"></div>
                                    <div class="col-6 forms-block mt-4">
                                        <div class="col-3 paddingleft0">
                                            <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20" style="box-shadow:none;">
                                                <div class="mr-17 pb-2 float-l">
                                                    <b-button size="lg" variant="primary" @click="addCompany">
                                                        <template v-if="$route.params.id > 0">Update</template>
                                                        <template v-else>Save</template>
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 paddingleft0">
                                            <div class="button-box outline-button QuizButton bg-color-ffffff radius-5 border-d3dee3 default-bx-shadow float-l ml-20">
                                                <div class="mr-17 pb-2 float-l">
                                                    <b-button size="lg" variant="secondary" @click="buttonBack">Cancel</b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 5th Row -->


                                </template>
                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <backend-footer></backend-footer>
    </div>
    <!-- /#page-content-wrapper -->
</template>

<script>
    import Vue from "vue";
    import { HTTP } from "../../../http-common";
    import BackendFooter from "@/components/backend/partials/footer";
    import { commonMixins } from "../../../mixins/commonMixins";
    import vSelect from "vue-select";
    import SlugInput from "vue-slug-input";

    import EventBus from "../../event-bus";
    import moment from "moment";
    import VueTagsInput from '@johmun/vue-tags-input';

    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    Vue.use(SlugInput);
    var self;

    export default {
        name: "AddEditSurvey",
        components: {
            "v-select": vSelect,
            "backend-footer": BackendFooter,
            VueTagsInput,
            'vueDropzone': vue2Dropzone,
        },
        mixins: [commonMixins],
        data() {
            return {
                pageTitle: "Add Company",
                companyName : "",
                companyNameError : "",

                dropzoneOptions: {
                    timeout: 3000000,
                    url: process.env.VUE_APP_ROOT_API + "/uploadmedia",
                    method: "POST",
                    paramName: 'file',
                    autoProcessQueue: true,
                    maxFilesize: 250, //MB
                    dictFileTooBig: "File is to big ({{filesize}}MB). Max allowed file size is {{maxFilesize}}MB",
                    thumbnailWidth: 150, //px
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictCancelUpload: 'Cancel',
                    dictRemoveFile: "Remove",
                    acceptedFiles: ".jpg, .jpeg, .png",
                    maxFiles: 1,
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token"),
                        // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',//'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type',
                        //'Content-Type': 'application/x-www-form-urlencoded'
                    }
                },

                //Files
                imagesPaths: [],
                imagesType: [],
                imagesSize: [],
                loadDocArr: [],
                fileUploadError : "",
            };
        },
        beforeMount: function() {
            EventBus.$emit("menu-selection-changed", "listcompany");
        },
        mounted: function() {
            self = this;
            if (self.$route.params.id > 0) {
                // Fetch Survey by Id.
                self.fetchLogoById(self.$route.params.id);
            }
        },
        watch: {

        },
        methods: {
            uploadDocument: function (file, response) {
                var self = this;
                self.imagesPaths.push(response.content.media_path);
                self.imagesType.push(file.type);
                self.imagesSize.push(file.size);
                // this.$refs.dropzone.setOption('maxFiles', null);
                self.fileUploadError = '';
            },
            removedSelectedFile: function (file, xhr, error) {
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
                // this.$refs.dropzone.setOption('maxFiles', null);
                var self = this;
                var removeFile = "";
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* if (self.imagesPaths.length <= 0) {
                 self.isFileUploaded = false;
                 } */
                if ("manuallyAdded" in file == true) {
                    for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                        if (self.imagesPaths[i].indexOf(file.name) != -1) {
                            removeFile = self.imagesPaths[i];
                        }
                    }
                    // this.$refs.dropzone.setOption('maxFiles', null);
                } else {
                    var filePath = JSON.parse(file.xhr.response);
                    removeFile = filePath.content;
                    self.removeFileFromServer(removeFile);
                }
                for (var i = self.imagesPaths.length - 1; i >= 0; i--) {
                    if (self.imagesPaths[i] === removeFile) {
                        self.imagesPaths.splice(i, 1);
                        self.imagesType.splice(i, 1);
                        self.imagesSize.splice(i, 1);
                        //break;       //<-- Uncomment  if only the first term has to be removed
                    }
                }
                // this.$refs.dropzone.setOption('maxFiles', null);
                /* console.log(JSON.stringify(this.$refs.dropzone.dropzone.options)); */
            },
            removeFileFromServer: function (filePath) {
                var self = this;
                //console.log(filePath);return false;
                var config = {
                    headers: {"Authorization": "Bearer " + localStorage.getItem("api_token")},
                    data: {
                        filePath: filePath,
                    }
                };
                HTTP.delete('removemedia', config)
                    .then(function (response) {
                        /*console.log("File removed successfully");*/
                    })
                    .catch(function (err) {
                        //self.catchHandler(err, function () {});
                        if (err.response.status == 0) {
                            self.error = "Remote server can not be reachable";
                        } else {
                            //redirect to login page if user not authenticated
                            if (err.response.status == 401) {
                                //clear previous data
                                localStorage.removeItem("user_obj");
                                localStorage.removeItem("api_token");
                                localStorage.removeItem("profile_image");
                                self.$router.push("/login");
                                //EventBus.$emit("logged-in", true, "", "");
                            }
                            self.error = err.response.data.message;
                        }
                    });
            },
            fileError: function (file, xhr, error) {
                /* console.log("File Error"); */
            },
            fileAdd: function (file, xhr, error) {
                if (this.$refs.dropzone.dropzone.options.maxFiles) {
                    // this.$refs.dropzone.setOption('maxFiles', null);
                }
                /* console.log("File Add"); */
            },
            uploadManually: function (file) {
                var self = this;
                console.log(JSON.stringify(self.loadDocArr));
                if(self.loadDocArr.length > 0){
                    for (var i = 0; i < self.loadDocArr.length; i++) {
                        if (self.loadDocArr[i]['media_from'] == 'media_url') {
                            var mockFile = {name: "image_" + i + ".jpg", size: self.loadDocArr[i]['size']};
                            let imgUrl = self.loadDocArr[i]['thumb_url'];
                            this.$refs.dropzone.dropzone.options.addedfile.call(this.$refs.dropzone.dropzone, mockFile);
                            this.$refs.dropzone.dropzone.options.thumbnail.call(this.$refs.dropzone.dropzone, mockFile, imgUrl);
                        } else {
                            var url = self.loadDocArr[i]['media_path'];
                            var file_name = self.getFileName(self.loadDocArr[i]['media_path']);
                            var file_type = self.loadDocArr[i]['type'];
                            var file_size = self.loadDocArr[i]['size'];

                            self.imagesType.push(file_type);
                            self.imagesSize.push(Number(file_size));

                            var file_data = {size: file_size, fileid: file_name, name: file_name, type: file_type};
                            self.$refs.dropzone.manuallyAddFile(file_data, url);
                        }
                    }
                }
            },
            getFileType: function (file_name) {

                var type = file_name.split('.');
                var file_type = type[type.length - 1];
                return 'image/' + file_type;
            },
            getFileName: function (file) {

                var self = this;
                var src = file.split('/');
                var file_name = src[src.length - 1];
                var orgPath = src[src.length - 2];

                self.imagesPaths.push(String(orgPath + "/" + file_name));

                return file_name;
            },
            removeAllFiles: function () {
                this.$refs.dropzone.removeAllFiles();
            },
            addCompany: function() {
                if (!self.validateForm()) {
                    return;
                }
                var data = {
                    id: self.$route.params.id,
                    company_name : self.companyName,
                    imagePath: self.imagesPaths,
                    imageSize: self.imagesSize,
                    imageType: self.imagesType,
                };

                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    }
                };

                var apiUrl = "logo/add/update";
                self.showLoader();
                HTTP.post(apiUrl, data, config)
                    .then(function(response) {
                        self.hideLoader();
                        console.log(response);
                        if (response.data.code == 200) {
                            self.showSAlert(
                                response.data.message,
                                self.saOpTypes.SUCCESS,
                                function() {
                                    self.$router.push("/backend/logo/list");
                                }
                            );
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        self.catchHandler(err, function(params) {
                            console.log(params);
                        });
                    });
            },
            fetchLogoById: function() {
                var config = {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("api_token")
                    },
                    params: {
                        id: self.$route.params.id
                    }
                };
                self.showLoader();
                HTTP.get("logo/getbyId", config)
                    .then(function(response) {
                        self.hideLoader();
                        if (response.data.code == 200) {
                            self.companyName = response.data.content.company_name;
                            self.loadDocArr = response.data.content['media'];
                            self.uploadManually();
                        }
                    })
                    .catch(function(err) {
                        self.hideLoader();
                        self.catchHandler(err, function() {});
                    });
            },
            buttonBack: function() {
                self.$router.push("/backend/logo/list");
            },


            validateForm() {
                var checked = true;


                if (!self.companyName) {
                    self.companyNameError = "Please enter company name";
                    if (checked) {
                        checked = false;
                    }
                }

                if (self.imagesPaths.length <= 0) {
                    self.fileUploadError = "Please upload image";
                    if (checked) {
                        checked = false;
                    }
                }
                return checked;
            },

        }
    };
</script>
